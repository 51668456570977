<template>
    <div class="col-6 col-md-6 col-lg-3 mb-3">
        <div class="card main-card">
            <div class="card-body">
                <v-card class="mx-auto" max-width="400">
                    <freedom-discount :discountFrom="rewardRateMin" :discountTo="rewardRateMax" :color="'primary'" />
                    <v-img class="black--text align-end" max-height="200px" :src="baseURL + thumbnail">
                    </v-img>
                    <v-card-text class="pb-0 card-title-htl fsize-2" :class="'price-primary'">
                        {{ (value / htlEur).toFixed(2) }} HTL
                    </v-card-text>
                </v-card>
                <v-dialog v-model="dialog" max-width="500px">
                    <v-card elevation="20">
                        <v-card-title class="headline grey lighten-2" primary-title>{{
                            $t('join-freedom-program') }}</v-card-title>
                        <v-card-title class="headline">
                            <template>
                                {{ $t('join-freedom-program-for-of', [(value / htlEur).toFixed(2), (available /
                                    1e8).toFixed(2)]) }}
                            </template>
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <template v-if="this.$props.freedomProgramCount < 3">
                                <v-btn class="text-unset" small light flat @click="dialog = false">{{ $t('close') }}</v-btn>
                                <v-btn color="primary" class="text-unset" small dark @click="dialog2Confirmation = true">{{
                                    $t('join-freedom-program') }}</v-btn>
                            </template>
                            <template v-else>
                                <v-btn class="text-unset" small light flat @click="dialog = false">OK</v-btn>
                            </template>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialog2Confirmation" max-width="500px">
                    <v-card elevation="20">
                        <v-card-title class="headline grey lighten-2" primary-title>Confirm </v-card-title>
                        <v-card-title class="headline">
                            <template>
                                <div v-if="this.$props.freedomProgramCount === 1">You already have <u>one</u> active Freedom
                                    Program. Are you sure you want to activate a new one?</div>
                                <div v-else-if="this.$props.freedomProgramCount === 2">You already have <u>two</u> active
                                    Freedom Programs. Are you sure you want to activate a new one?</div>
                                <div v-else-if="this.$props.freedomProgramCount > 2">You can't have more than <u>three</u>
                                    active Freedom Programs.</div>
                                <div v-else>Confirm the start of the freedom program?</div>
                            </template>
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <template v-if="this.$props.freedomProgramCount < 3">
                                <v-btn class="text-unset" small light flat @click="dialog2Confirmation = false">No</v-btn>
                                <v-btn color="primary" class="text-unset" small dark @click="buy">Yes</v-btn>
                            </template>
                            <template v-else>
                                <v-btn class="text-unset" small light flat @click="dialog2Confirmation = false">OK</v-btn>
                            </template>

                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
            <div class="card-footer">
                <v-btn color="primary" text elevation="24" block small @click="dialog = true"
                    v-if="available > value / htlEur * 1e8" :class="['btn-pack-primarytext-unset']">
                    <template>
                        {{ $t('join-freedom-program') }}
                    </template>

                </v-btn>
                <buy-lock v-else :programType="programType" :programDeposit="programDeposit" :available="available"
                    :price="value" :color="'primary'" :pack="pack" :deposit-address="depositAddress" :parent="null" />
            </div>
        </div>
    </div>
</template>
<script>
//TODO: vprasaj kako je z locki exchange rateov

import FreedomDiscount from "./FreedomDiscount";
import BuyLock from "./BuyLock";
import { mapGetters } from "vuex";
import gql from "graphql-tag";
export default {
    name: "FreedomProgramPackageBox",
    components: {
        FreedomDiscount,
        BuyLock
    },
    props: ["programType", "programDeposit", "rewardRateMin", "rewardRateMax", "value", "available", "rate", "depositAddress", "thumbnail", "freedomProgramName", "packId", "freedomProgramCount", "eurUsd"],
    data() {
        return {
            rates: {
                htlEur: 0.95
            },
            dialog: false,
            dialog2Confirmation: false,
            pack: {
                price: this.value,
                name: this.freedomProgramName,
                id: this.packId
            }
        }
    },
    created() {

    },
    computed: {
        ...mapGetters({
            htlEur: 'prices/htlEur',
            token: 'prices/rate'
        })
    },
    methods: {
        join() {
            this.$apollo.mutate({
                mutation: gql`mutation($id:ID!,$token:String!){joinFreedomProgram(type:$id,token:$token,programType:$programType)}`,
                variables: {
                    id: this.value.id,
                    token: this.token,
                    programType: this.programType
                },
            }).then(() => {
                this.$router.push("/freedom-program")
            });
        },
        buy() {
            console.log(this.$props.packId, this.$props.value);
            this.dialog2Confirmation = false;
            this.dialog = false;

            this.$apollo.mutate({
                // Query
                mutation: gql`mutation($packId:String!,$token:String!,$eurUsd:Float){startFreedomProgram(packId:$packId,token:$token,eurUsd:$eurUsd)}`,
                // Parameters
                variables: {
                    packId: this.$props.packId,
                    token: this.token,
                    eurUsd: this.eurUsd

                },
            }).then(() => {
                this.$router.go(0);
            })


        }
    }
}
</script>
<style scoped>
.widget-chart {
    min-height: 220px;
    padding: 0;
}

.widget-chart-wrapper {
    left: auto;
    bottom: auto;
    opacity: 0.9;
}

.widget-bg-img {
    max-height: 170px;
    width: 100%;
    position: center;
    z-index: -1 !important;
}

.widget-price {
    padding-top: 120px;
    padding-left: 20px;
    position: left;
}

.widget-btns {
    padding-top: 170px;
}

.btn-pack-primary {
    background-color: #56aaff !important;
}

.btn-pack-secondary {
    background-color: #8bca63 !important;
}

.btn-pack-tertiary {
    background-color: #D4AF37 !important;
}

.price-primary {
    color: #56aaff;
}

.price-secondary {
    color: #8bca63;
}

.price-tertiary {
    color: #2f485b;
}

.card-title-htl {
    font-weight: 600;
    font-size: 2rem;
    padding-left: 15px;
}

@media (max-width: 420px) {
    .card-title-htl {
        font-size: 1rem;
        padding-left: 5px;
    }

    .card-footer {
        padding: 0.1rem 5px;
    }
}

.card-body {
    padding: 0.6rem;
}
</style>
